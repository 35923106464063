<style>
.border-0 {
  border-radius: 0px !important;
}
</style>
<template>
  <div>
    <v-row class="mx-0">
      <v-col md="3  ">
        <v-menu v-model="filter.date_menu" :close-on-content-click="false" :nudge-right="40" min-width="290px" offset-y
          transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-text-field v-model="filter.display_daterange" v-on="on" class="filter" dense filled
              label="Filter by date Range" outlined placeholder="Filter by date Range" readonly single-line>
              <template slot="append">
                <v-icon v-if="filter.daterange.length > 1" @click="clearDateFilter()">mdi-close</v-icon>
              </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="filter.daterange" range>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="
              filter.daterange = [];
            filter.date_menu = false;
            ">Cancel</v-btn>
            <v-btn color="primary" text @click="formateDisplayDate">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col v-if="this.filter_status == $keys.INSTALLMENT_OVERDUE" class="text-center" cols="12" md="3">
        <v-menu v-model="fd_date_menu" :close-on-content-click="false" :nudge-right="40" min-width="290px" offset-y
          transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-text-field v-model="filter.fd_display_date" v-on="on" :label="$lang.FOLLOW_UP_DATE"
              :placeholder="$lang.FOLLOW_UP_DATE" class="filter" dense filled outlined readonly single-line>
              <template slot="append">
                <v-icon v-if="filter.follow_up_date" @click="
                  filter.follow_up_date = null;
                filter.fd_display_date = null;
                clearDateFilter();
                ">mdi-close
                </v-icon>
              </template>
            </v-text-field>
          </template>
          <v-date-picker v-model="filter.follow_up_date">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="date_menu = false">Cancel</v-btn>
            <v-btn color="primary" text @click="formateDisplayDate">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <!-- call tag filter -->
      <v-col v-if="this.filter_status == $keys.INSTALLMENT_OVERDUE" class="text-center" cols="12" md="3">
        <v-select v-model="filter.call_tag" :items="list_of_tags" clearable item-text="call_tag" item-value="call_tag"
          dense filled hide-details label="Select Call Tag" menu-props="auto" outlined placeholder="Select Call Tag"
          single-line @change="getInstallmentList(filter_status, true)" @click="getListOfTags()">
        </v-select>
      </v-col>

      <!-- overdue days filter -->
      <v-col v-if="this.filter_status == $keys.INSTALLMENT_OVERDUE" class="text-center" cols="12" md="3">
        <v-select v-model="filter.date_bucket_id" :items="overdue_date_list" clearable item-text="days_bucket_list"
          item-value="id" dense filled hide-details label="Select Overdue Date" menu-props="auto" outlined
          placeholder="Select Overdue Date" single-line @change="getInstallmentList(filter_status, true)"
          @click="getOverdueDateList()">
        </v-select>
      </v-col>
      <v-col class="text-center mb-5" cols="12" md="3">
        <v-select :items="agent_type_list" item-value="value" item-text="text" v-model="agent_type" class="filter" dense
          filled label="Select Agent Type" outlined placeholder="Select Agent Type" clearable>
        </v-select>
      </v-col>
      <v-col v-if="agent_type" class="text-center mb-5" cols="12" md="3">
        <v-combobox v-model="filters.agent_assigned" :items="agent_list" :loading="agent_loading"
          :search-input.sync="agent_search" clearable dense filled hide-details item-text="name" item-value="id"
          label="Select agent" outlined placeholder="Select agent" single-line @focus="getAgentList" @keyup="getAgentList"
          @change="getInstallmentList(filter_status, true)">
          <template v-slot:append-item>
            <div v-intersect="endIntersectAgent"></div>
          </template>
        </v-combobox>
      </v-col>
    </v-row>

    <FilterTab :filter_status="filter_status" :total_due_emi_count="total_due_emi_count"
      :total_over_due_emi_count="total_over_due_emi_count" :total_paid_emi_count="total_paid_emi_count"
      @update="updateFilter" :total_overdue="total_overdue" :upcoming_amount="upcoming_amount"
      :overdue_amount="overdue_amount" :overdue_charge="overdue_charge" :total_paid_amount="total_paid_amount"
      :paid_amount="paid_amount" :paid_charge="paid_charge" :total_overdue_user_count="total_overdue_user_count"
      :total_due_user_count="total_due_user_count" :total_paid_user_count="total_paid_user_count"></FilterTab>
    <v-card flat>
      <v-stepper v-model="step" class="elevation-0">
        <v-stepper-items class="auth-basic">
          <v-stepper-content class="pa-0" step="1">
            <OverDueEmiTable :installment_list="itemsWithSno" :table_loading="table_loading"
              @reload_installments="getInstallmentList"></OverDueEmiTable>
          </v-stepper-content>

          <v-stepper-content class="pa-0" step="2">
            <UpcomingEmiTable :installment_list="itemsWithSno" :table_loading="table_loading"></UpcomingEmiTable>
          </v-stepper-content>

          <v-stepper-content class="pa-0" step="3">
            <PaidEmiTable :installment_list="itemsWithSno" :table_loading="table_loading"></PaidEmiTable>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>

    <v-pagination v-model="page_number" :length="total_page_count" :total-visible="7"
      class="custome-pagination bg-transparent" @input="getInstallmentList(filter_status, true)"></v-pagination>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {
    FilterTab: () => import("@/components/emi-analysis/FilterTab"),
    OverDueEmiTable: () => import("@/components/emi-analysis/OverDueEmiTable"),
    UpcomingEmiTable: () =>
      import("@/components/emi-analysis/UpcomingEmiTable"),
    PaidEmiTable: () => import("@/components/emi-analysis/PaidEmiTable"),
  },
  data() {
    return {
      table_loading: false,
      page_number: 1,
      total_page_count: 2,
      step: 1,
      installment_list: [],
      total_over_due_emi_count: 0,
      total_due_emi_count: 0,
      total_paid_emi_count: 0,
      filter_status: "Overdue",
      fd_date_menu: false,

      total_paid_amount: 0,
      paid_charge: 0,
      paid_amount: 0,
      overdue_charge: 0,
      overdue_amount: 0,
      total_overdue: 0,
      upcoming_amount: 0,
      total_overdue_user_count: 0,
      total_due_user_count: 0,
      total_paid_user_count: 0,

      filter: {
        date_menu: false,
        daterange: [],
        display_daterange: null,
        fd_display_date: null,
        follow_up_date: null,
        call_tag: null,
        date_bucket_id: null,
        today: new Date().toISOString().substr(0, 10),
      },
      list_of_tags: [],
      overdue_date_list: [],

      agent_list: [],
      agent_loading: false,
      agent_page: 1,
      agent_search: "",
      agent: "",
      agent_type: "",
      agent_type_list: [
        {
          id: 1,
          text: "Assigned",
          value: true,
        },
        {
          id: 2,
          text: "Unassigned",
          value: false,
        },
      ],
    };
  },
  destroyed() {
    this.filters.agent_assigned = "";
  },
  computed: {
    ...mapGetters({
      search_query: "getSearchText",
      filters: "loan_applied/getFilters",
    }),
    itemsWithSno() {
      return this.installment_list.map((d, index) => ({
        ...d,
        sno: index + 1,
      }));
    },
  },
  watch: {
    search_query(new_search_query, old_search_query) {
      this.getInstallmentList(this.filter_status, true);
    },
    agent_type(value) {
      if (!value) {
        this.filters.agent_assigned = ""
        this.getInstallmentList(this.filter_status, true);
      }
    },
  },
  created() {
    this.getInstallmentList(this.filter_status, true);
  },
  methods: {
    updateFilter(filter_status, step) {
      this.filter_status = filter_status;
      this.step = step;
      this.getInstallmentList(filter_status, true);
    },
    endIntersectAgent(entries, observer, isIntersectingAgent) {
      if (isIntersectingAgent) {
        // this.agent_page++;
        this.getAgentList(true);
      }
    },
    /* fetching the customer list form server*/
    getInstallmentList(filter_status = "Overdue", isCancel = false) {
      const self = this;
      self.getOverdueEmiAmount();
      this.installment_list = [];
      console.log(filter_status);
      self.table_loading = true;
      self.toggle_loading();
      // if (self.filters.agent_assigned) {
      //   var agent_search_query = this.agent_type ? self.filters.agent_assigned.name: "";
      // }
      let params = {
        page_number: this.page_number,
        page_length: this.$keys.PAGE_LENGTH,
        search_query: this.search_query,
        installment_status: filter_status,
        call_tag: "",
        date_bucket_id: null,
        is_assigned: this.agent_type,
      };
      if (self.filters.agent_assigned && this.agent_type) {
        params.agent_search_query = self.filters.agent_assigned.name;
      }

      if (this.filter.call_tag) {
        params.call_tag = this.filter.call_tag;
      }
      if (this.filter.date_bucket_id) {
        params.date_bucket_id = this.filter.date_bucket_id;
      }
      if (this.filter.daterange) {
        params.from_date = this.filter.daterange[0];
        params.to_date = this.filter.daterange[1];
      }
      if (this.filter.follow_up_date)
        params.follow_up_date = this.filter.follow_up_date;
      console.log(params);
      const successHandler = (response) => {
        self.table_loading = false;
        if (response.data.success) {
          self.installment_list = response.data.installment_list;
          self.total_page_count = response.data.total_page_count;
          self.total_over_due_emi_count =
            response.data.total_over_due_emi_count;
          self.total_due_emi_count = response.data.total_due_emi_count;
          self.total_paid_emi_count = response.data.total_paid_emi_count;

          self.total_overdue_user_count =
            response.data.total_overdue_user_count;
          self.total_due_user_count = response.data.total_due_user_count;
          self.total_paid_user_count = response.data.total_paid_user_count;
        }
      };
      const finallyHandler = () => {
        // self.table_loading = false;
        // self.toggle_loading()
      };

      self.request_GET(
        self,
        self.$urls.INSTALLMENT_LIST,
        params,
        successHandler,
        null,
        null,
        finallyHandler,
        isCancel
      );
    },
    /* fetching the amount details of overdue, due and paid emi form server*/
    getOverdueEmiAmount() {
      const self = this;

      const successHandler = (response) => {
        // if (response.data.success) {
        self.total_paid_amount = response.data.total_paid_amount_v2;
        self.paid_charge = response.data.paid_charges_v2;
        self.paid_amount = response.data.paid_amount;
        self.overdue_charge = response.data.overdue_charge;
        self.overdue_amount = response.data.overdue_amount;
        self.total_overdue = response.data.total_overdue;
        self.upcoming_amount = response.data.upcoming_amount;
        // }
      };

      self.request_GET(
        self,
        self.$urls.GET_EMI_AMOUNT_DETAILS,
        null,
        successHandler,
        null,
        null,
        null
      );
    },
    formateDisplayDate() {
      if (this.filter.follow_up_date)
        this.filter.fd_display_date = this.display_date_formate(
          this.filter.follow_up_date
        );
      if (this.filter.daterange)
        this.filter.display_daterange = this.formateDisplayDateRange(
          this.filter.daterange
        );
      this.filter.date_menu = false;
      this.fd_date_menu = false;
      this.getInstallmentList(this.filter_status, true);
    },
    clearDateFilter() {
      this.filter.daterange = [];
      this.filter.display_daterange = null;
      this.filter.follow_up_date = null;
      this.getInstallmentList(this.filter_status, true);
    },
    formateDisplayDateRange(daterange) {
      console.log(daterange);
      let date1 = daterange[0];
      let date2 = daterange[1];
      let to = "";
      let from = "";
      if (date1 > date2) {
        to = date1;
        from = date2;
      } else {
        to = date2;
        from = date1;
      }
      console.log(from);
      if (!to) to = from;
      daterange[0] = from;
      daterange[1] = to;
      from = this.display_date_formate(from);
      to = this.display_date_formate(to);
      return `${from} to ${to}`;
    },
    // to fetch call tag list
    getListOfTags() {
      const self = this;
      const successHandler = (response) => {
        if (response.data.success) {
          self.list_of_tags = response.data.tags[0];
        }
      };
      self.request_GET(
        self,
        self.$urls.CALL_TAG_LIST,
        null,
        successHandler,
        null,
        null,
        null
      );
    },
    getOverdueDateList() {
      const self = this;
      let look_up_key = "";
      let params = {};
      const successHandler = (response) => {
        self.overdue_date_list = response.data.result;
      };
      const finallyHandler = () => { };
      self.GET_request(
        self,
        self.$urls.OVERDUE_DATE_LIST,
        look_up_key,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    getAgentList(append_agent = false) {
      const self = this;
      self.agent_loading = true;
      let params = {
        search_query: this.agent_search,
        page_number: this.agent_page,
      };
      // console.log(params);
      const successHandler = (response) => {
        // console.log("Team list",response);
        self.agent_list = response.data.result.user_data;
        self.agent_list.unshift({
          name: "All",
        });
      };
      const finallyHandler = () => {
        self.agent_loading = false;
      };

      self.request_GET(
        self,
        self.$urls.TEAM_LIST,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
};
</script>
