var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{attrs:{"md":"3  "}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"290px","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"filter",attrs:{"dense":"","filled":"","label":"Filter by date Range","outlined":"","placeholder":"Filter by date Range","readonly":"","single-line":""},model:{value:(_vm.filter.display_daterange),callback:function ($$v) {_vm.$set(_vm.filter, "display_daterange", $$v)},expression:"filter.display_daterange"}},on),[_c('template',{slot:"append"},[(_vm.filter.daterange.length > 1)?_c('v-icon',{on:{"click":function($event){return _vm.clearDateFilter()}}},[_vm._v("mdi-close")]):_vm._e()],1)],2)]}}]),model:{value:(_vm.filter.date_menu),callback:function ($$v) {_vm.$set(_vm.filter, "date_menu", $$v)},expression:"filter.date_menu"}},[_c('v-date-picker',{attrs:{"max":_vm.filter.today,"range":""},model:{value:(_vm.filter.daterange),callback:function ($$v) {_vm.$set(_vm.filter, "daterange", $$v)},expression:"filter.daterange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.filter.daterange = [];
          _vm.filter.date_menu = false;}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.formateDisplayDate}},[_vm._v("OK")])],1)],1)],1),_c('v-col',{attrs:{"md":"3"}},[_c('v-select',{attrs:{"items":_vm.payment_mode_option,"clearable":"","dense":"","filled":"","hide-details":"","label":"Select Payment Mode","menu-props":"auto","outlined":"","placeholder":"Select Loan Status","single-line":""},on:{"change":_vm.getPaymentList},model:{value:(_vm.payment_mode),callback:function ($$v) {_vm.payment_mode=$$v},expression:"payment_mode"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('v-select',{attrs:{"items":_vm.loan_status_options,"clearable":"","dense":"","filled":"","hide-details":"","menu-props":"auto","outlined":"","placeholder":"Select Loan Status","single-line":""},on:{"change":_vm.getPaymentList},model:{value:(_vm.loan_status),callback:function ($$v) {_vm.loan_status=$$v},expression:"loan_status"}})],1)],1),_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"border-a border-0 custome-header-bg",attrs:{"disable-sort":true,"headers":_vm.headers,"items":_vm.payment_list,"items-per-page":_vm.$keys.PAGE_LENGTH,"loading":_vm.table_loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"mt-1"},[_vm._v(_vm._s(item.name))]),_c('p',[_vm._v(_vm._s(item.mobile))]),_c('v-chip',{staticClass:"mb-1 pr-4",attrs:{"small":"","color":_vm.getColor(item.current_loan_status),"outlined":""}},[_vm._v(_vm._s(item.current_loan_status))])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1",attrs:{"depressed":"","fab":"","small":""},on:{"click":function($event){return _vm.openCommentDialog(item)}}},[_c('v-icon',{attrs:{"color":"secondary_2"}},[_vm._v("mdi-message-text")])],1)]}}])})],1),_c('v-pagination',{staticClass:"custome-pagination",attrs:{"length":_vm.total_page_count,"total-visible":7},on:{"input":_vm.getPaymentList},model:{value:(_vm.page_number),callback:function ($$v) {_vm.page_number=$$v},expression:"page_number"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }