<template>
  <div>

    <v-row class="mx-0">
      <v-col md="3">
        <v-menu
            v-model="filter.date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            min-width="290px"
            offset-y
            transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                v-model="filter.display_daterange"
                v-on="on"
                class="filter"
                dense
                filled
                label="Filter by date Range"
                outlined
                placeholder="Filter by date Range"
                readonly
                single-line
            >
              <template slot="append">
                <v-icon v-if="filter.daterange.length>1" @click="clearDateFilter()">mdi-close</v-icon>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
              v-model="filter.daterange"
              :max="filter.today"
              range
          >
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="filter.daterange=[];filter.date_menu = false">Cancel</v-btn>
            <v-btn color="primary" text @click="formateDisplayDate">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>


    <v-card flat>
      <v-data-table
          :disable-sort="true"
          :headers="headers"
          :items="review_list"
          :items-per-page="$keys.PAGE_LENGTH"
          :loading="table_loading"
          class="border-a border-0 custome-header-bg"
          hide-default-footer
      >
        <template v-slot:item.name="{ item }">
          <div class="py-2">
            <p>{{ item.name }}</p>
            <p>{{ item.mobile }}</p>
          </div>
        </template>

        <template v-slot:item.rating="{ item }">
          <div class="py-2">
            <v-rating
                v-model="item.rating"
                background-color="secondary_2"
                color="secondary_2"
                dense
                half-increments
                readonly
            ></v-rating>
<!--            <p class="text-center">( {{ item.rating }} )</p>-->
          </div>
        </template>

      </v-data-table>
    </v-card>

    <v-pagination
        v-model="page_number"
        :length="total_page_count"
        :total-visible="7"
        class="custome-pagination"
        @input="getReviewList"
    ></v-pagination>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  components: {},
  data() {
    return {
      table_loading: false,
      page_number: 1,
      total_page_count: 0,
      review_list: [],
      date_range: [],
      headers: [
        {text: 'Customer Details', value: 'name'},
        {text: 'Rating', value: 'rating', align: 'center'},
        {text: 'Review', value: 'review'},
        {text: 'Feedback', value: 'feedback'},
        {text: 'Date', value: 'created', width: "120px"},
      ],
      filter: {
        date_menu: false,
        daterange: [],
        display_daterange: null,
        today: new Date().toISOString().substr(0, 10),
      },
    }
  },
  computed: {
    ...mapGetters({
      search_query: 'getSearchText'
    })
  },
  watch: {
    search_query(new_search_query, old_search_query) {
      if (new_search_query != old_search_query) {
        this.getReviewList()
      }

    }
  },
  created() {
    console.log(this.$route.meta.show_back_btn)
    this.getReviewList()
  },
  methods: {
    formateDisplayDate() {
      this.filter.display_daterange = this.formateDisplayDateRange(this.filter.daterange)
      this.filter.date_menu = false;
      this.getReviewList()
    },
    clearDateFilter() {
      this.filter.daterange = []
      this.filter.display_daterange = null
      this.getReviewList()
    },
    formateDisplayDateRange(daterange) {
      console.log(daterange)
      let date1 = daterange[0]
      let date2 = daterange[1]
      let to = "";
      let from = "";
      if (date1 > date2) {
        to = date1
        from = date2
      } else {
        to = date2;
        from = date1
      }
      console.log(from)
      if (!to)
        to = from
      daterange[0] = from
      daterange[1] = to
      from = this.display_date_formate(from)
      to = this.display_date_formate(to)
      return `${from} to ${to}`;
    },
    /* fetching the customer list form server*/
    getReviewList() {
      const self = this;
      self.table_loading = true;
      self.toggle_loading()
      let params = {
        page_number: this.page_number,
        page_length: this.$keys.PAGE_LENGTH,
        search_query: this.search_query,
        from_date: this.filter.daterange[0],
        to_date: this.filter.daterange[1],
      };
      console.log(params);
      const successHandler = (response) => {
        if (response.data.success) {
          self.review_list = response.data.review_list;
          self.total_page_count = response.data.total_page_count;
        }
      };
      const finallyHandler = () => {
        self.table_loading = false;
        // self.toggle_loading()
      };

      self.request_GET(self, self.$urls.REVIEW_LIST, params, successHandler, null,
          null, finallyHandler);
    },
  }
}

</script>